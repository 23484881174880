import React from "react"
import NavigationMenu from "./navigation-menu"

const Layout = ({ wide, children }) => {
  return (
    <div className="container pt-14 mx-auto max-w-5xl text-gray-900">
      <header className="">
          <NavigationMenu />
      </header>
      <main>{children}</main>
    </div>
  )
}

export default Layout
